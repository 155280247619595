/* eslint-disable no-param-reassign */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getCurrentToken, setCurrentToken } from './Utils';

const fetchClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
});

const refreshAuthLogic = (failedRequest) =>
  axios
    .post(
      `${process.env.REACT_APP_BACKEND}refresh`,
      {
        refresh: getCurrentToken().refreshToken,
      },
      { skipAuthRefresh: true }
    )
    .then((tokenRefreshResponse) => {
      const { accessToken, refreshToken } = tokenRefreshResponse.data;
      setCurrentToken(JSON.stringify({ accessToken, refreshToken }));
      failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;
      return Promise.resolve();
    })
    .catch((err) => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    });

createAuthRefreshInterceptor(fetchClient, refreshAuthLogic, {
  interceptNetworkError: true,
});

fetchClient.interceptors.request.use((config) => {
  const token = getCurrentToken();
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token && token.accessToken}`,
  };

  return config;
});

export default fetchClient;
