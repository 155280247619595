import {
    METRICS_GET_LIST,
    METRICS_GET_LIST_ERROR,
    METRICS_GET_LIST_SUCCESS,
  } from '../contants';
  
  const INIT_STATE = {
    metricsItems: null,
    error: '',
    loaded: false,
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case METRICS_GET_LIST:
        return { ...state, loaded: false };
  
      case METRICS_GET_LIST_SUCCESS:
        return { ...state, loaded: true, metricsItems: action.payload };
  
      case METRICS_GET_LIST_ERROR:
        return { ...state, loaded: true, error: action.payload };
  
      default:
        return { ...state };
    }
  };
  