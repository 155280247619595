/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { getRoleCurrentUser } from 'helpers/Utils';
import {
  TRANSACTION_GET_LIST_ERROR,
  TRANSACTION_GET_LIST_SUCCESS,
  TRANSACTION_GET_LIST,
  TRANSACTION_SET_FILTER,
  GET_DETAILS_TRANSACTIONS,
  GET_DETAILS_TRANSACTIONS_SUCCESS,
  GET_DETAILS_TRANSACTIONS_ERROR,
  SET_SELECTED_TRANSACTION,
  RESET_SELECTED_TRANSACTIONS,
} from '../contants';

const INIT_STATE = {
  transactions: null,
  error: '',
  filter: {
    pageSize: 10,
  },
  searchKeyword: '',
  orderColumn: null,
  loaded: false,
  hasNextPage: false,
  hasPrevPage: false,
  page: 1,
  prevPage: null,
  nextPage: null,
  totalPages: null,
  endDate: null,
  startDate: null,
  transactionsSelected: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRANSACTION_GET_LIST:
      return { ...state, loaded: false };

    case TRANSACTION_GET_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loaded: true,
      };

    case TRANSACTION_GET_LIST_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case TRANSACTION_SET_FILTER:
      return {
        ...state,
        filter: action.payload?.filter,
        loaded: false,
      };

    case GET_DETAILS_TRANSACTIONS:
      return { ...state, loaded: false };

    case GET_DETAILS_TRANSACTIONS_SUCCESS:
      return { ...state, loaded: true, transactionSelected: action.payload };

    case GET_DETAILS_TRANSACTIONS_ERROR:
      return { ...state, loaded: true, error: action.payload };
    case SET_SELECTED_TRANSACTION:
      const stateCopy = state.transactionsSelected;
      if (typeof action.payload === 'boolean') {
        const currentUser = getRoleCurrentUser();
        const enabledTransactions = state.transactions.filter((transaction) => {
          if (currentUser === 'admin') {
            return transaction._id;
          }
          if (
            transaction !== undefined &&
            transaction !== null &&
            transaction !== '' &&
            ('bizumRecharge' in transaction ||
              'usdtWithdraw' in transaction ||
              'vebBankAccountWithdraw' in transaction ||
              'usdCashWithdraw' in transaction ||
              'pagoMovilWithdrawal' in transaction              
              )
          ) {
            return transaction._id;
          }
        });

        const transactionCopy = enabledTransactions.map(
          (transaction) => transaction._id
        );
        transactionCopy.forEach((transaction) => {
          const isDuplicate = stateCopy.indexOf(transaction);
          if (isDuplicate >= 0) {
            !action.payload && stateCopy.splice(isDuplicate, 1);
          } else {
            stateCopy.push(transaction);
          }
        });
      } else {
        const isDuplicate = stateCopy.indexOf(action.payload);
        if (isDuplicate >= 0) {
          stateCopy.splice(isDuplicate, 1);
          return {
            ...state,
            transactionsSelected: [...stateCopy],
          };
        }
        return {
          ...state,
          transactionsSelected: [...state.transactionsSelected, action.payload],
        };
      }
      return {
        ...state,
        transactionsSelected: [...stateCopy],
      };

    case RESET_SELECTED_TRANSACTIONS:
      return { ...state, filter: null, transactionsSelected: [] };

    default:
      return { ...state };
  }
};
