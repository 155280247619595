import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { configureStore } from './redux/store';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const Main = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={configureStore()}>
        <Suspense fallback={<div className="loading" />}>
          <App />
        </Suspense>
      </Provider>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));
