/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-return-assign */
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from 'constants/defaultValues';
import moment from 'moment';
import { useState } from 'react';
import { CardText, Popover, PopoverHeader, PopoverBody, Badge } from 'reactstrap';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const cropText = (text = '') => {
  const textLenght = text?.length;
  const firstLetters = text?.substring(0, 3);
  const latestLetters = text?.substring(textLenght - 3, textLenght);
  return `${firstLetters}...${latestLetters}`;
};

export const getDate = () => {
  const date = new Date();
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'setiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];
  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ];
  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const dayNumber = date.getUTCDate();
  return {
    day,
    month,
    dayNumber,
  };
};
export const getDateWithFormat = (date) => {
  const format = 'YYYY-MM-DD HH:mm:ss';
  return moment(date).format(format);
};

export const getDateWithFormatDDMMYYHHMMSS = (date) => {
  const format = 'DD-MM-YYYY HH:mm:ss';
  return moment(date).format(format);
}

export const getDateWithFormatDDMMYY = (date) => {
  const format = 'DD-MM-YYYY';
  return moment(date).format(format);
};

export const getDateWithFormatYYYYMMDD = (date) => {
  const format = 'YYYY-MM-DD';
  return moment(date).format(format);
};

export const getDateWithFormatByDate = (today) => {
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!
  const hh = today.toLocaleTimeString();

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}/${mm}/${yyyy} ${hh}`;
};

export const getDateWithFormatYMD = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${dd}`;
};

export const getDateLastMonth = () => {
  const dateLastMoth = new Date();  
  // Restar 1 mes a la fecha actual
  dateLastMoth.setMonth(dateLastMoth.getMonth() - 1);  
  return dateLastMoth;
}

export const getDateYesterday = () => {
  const today = new Date();
  let dd = today.getDate() - 1;
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${dd}`;
};

export const parseDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('es-ES', { month: 'long' });
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${day} de ${month} de ${year} a las ${hours}:${minutes}:${seconds}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};
export const setCurrentToken = (token) => {
  try {
    localStorage.setItem('token', token);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentToken-> error', error);
  }
};

export const getCurrentToken = () => {
  let token = null;
  let tokenResponse = null;
  try {
    token =
      localStorage.getItem('token') != null
        ? localStorage.getItem('token')
        : null;
    tokenResponse = JSON.parse(token);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getCurrentToken -> error',
      error
    );
    token = null;
  }
  return tokenResponse;
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('gogo_current_user') != null
        ? JSON.parse(localStorage.getItem('gogo_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const getRoleCurrentUser = () => {  
    const { role } = JSON.parse(localStorage.getItem('user'));
    return role;  
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('gogo_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const errorMsgUpdate = (msg) => {
  switch (msg) {
    case "Invalid status. Only 'completada' or 'cancelada' are allowed for this transaction.":
      return 'Solo se permiten los estatus "completada" o "cancelada" para esta transacción';
    case "This transaction can only be updated after 24 hours of creation.":
      return 'Esta transacción solo puede ser actualizada después de 24 horas de su creación';
    default:
      return 'Error al actualizar estatus';
  }
}

export const CopyText = ({ text, children }) => {  
  const [isCopied, setIsCopied] = useState(false);
  return (
    <CardText
    id="target"
    title={text}
    onClick={() => {
      navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }}
    style={{ cursor: 'pointer', fontSize: '16px' }}
    tabIndex="0"
    role="button"
  >
    {text?.length > 30 ? `${text?.substring(0,5)} ... ${text.substring(text?.length - 14)}` : text}
    {children}
    {isCopied && (
      <>      
      <Badge color="success" className="ml-2">
        Copiado!
      </Badge>
      </>
    )}
  </CardText>  
  ); 
};

export const valueNames = {
  usdCashWithdraw: 'RETIRO EN DOLARES',
  vebBankAccountWithdraw: 'RETIRO EN BOLIVARES',
  usdtWithdraw: 'RETIRO EN USDT',
  walletToWallet: 'ENVIOS COCO PAGO',
  pagoMovilWithdrawal: 'RETIRO EN BOLIVARES PAGO MOVIL',
  rampRecharge: 'RECARGA RAMP',
  zelleRecharge: 'RECARGA ZELLE',
  simplexRecharge: 'RECARGA SIMPLEX',
  bizumRecharge: 'RECARGA BIZUM',
  swapRequest: 'SOLICITUD DE INTERCAMBIO'
};

export const valueNamesForTable = {
  usdCashWithdraw: 'Retiro en dólares',
  vebBankAccountWithdraw: 'Envío en bolívares',
  usdtWithdraw: 'Retiro en USDT',
  walletToWallet: 'Envío Coco Pago',
  pagoMovilWithdrawal: 'Retiro en bolívares pago móvil',
  rampRecharge: 'Recarga Ramp',
  zelleRecharge: 'Recarga Zelle',
  simplexRecharge: 'Recarga Simplex',
  bizumRecharge: 'Recarga Bizum',
  swapRequest: 'Solicitud de intercambio'
}

export const formatNumber = (number) => {    
  return number.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 0});
}