import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'helpers/Axios';
import { METRICS_GET_LIST } from '../contants';
import { getMetricsSuccess, getMetricsError } from './actions';

const getMetricsRequest = async (date) => {
const {startDate, endDate, query} = date
// eslint-disable-next-line no-return-await
  return await axios
    .get(`/metrics?transactionType=${query}&startDate=${startDate}&endDate=${endDate}`)
    .then(({ data }) =>  data.metrics)
    .catch((error) =>  error.response.data);
};

function* getMetrics({ payload }) {
  const date = payload
  try {
    const response = yield call(getMetricsRequest, date);
    yield put(getMetricsSuccess(response));
  } catch (error) {
    yield put(getMetricsError(error));
  }
}

export function* watchGetDetail() {
  yield takeEvery(METRICS_GET_LIST, getMetrics);
}

export default function* rootSaga() {
  yield all([fork(watchGetDetail)]);
}
