import {
  TRANSACTION_GET_LIST_ERROR,
  TRANSACTION_GET_LIST_SUCCESS,
  TRANSACTION_GET_LIST,
  TRANSACTION_SET_FILTER,
  GET_DETAILS_TRANSACTIONS,
  GET_DETAILS_TRANSACTIONS_SUCCESS,
  GET_DETAILS_TRANSACTIONS_ERROR,
  SET_SELECTED_TRANSACTION,
  RESET_SELECTED_TRANSACTIONS,
} from '../contants';

export const getTransactionList = (type) => ({
  type: TRANSACTION_GET_LIST,
  payload: type,
});

export const getTransactionListSuccess = (items) => ({
  type: TRANSACTION_GET_LIST_SUCCESS,
  payload: items,
});

export const getTransactionListError = (error) => ({
  type: TRANSACTION_GET_LIST_ERROR,
  payload: error,
});

export const setTransactionFilter = (filter) => ({
  type: TRANSACTION_SET_FILTER,
  payload: filter,
});
export const setSelectedTransaction = (transactionID) => ({
  type: SET_SELECTED_TRANSACTION,
  payload: transactionID,
});
export const resetSelectedTransaction = () => ({
  type: RESET_SELECTED_TRANSACTIONS,
});
export const getDetailTransaction = (id) => ({
  type: GET_DETAILS_TRANSACTIONS,
  payload: id,
});

export const getDetailTransactionSuccess = (items) => ({
  type: GET_DETAILS_TRANSACTIONS_SUCCESS,
  payload: items,
});

export const getDetailTransactionError = (error) => ({
  type: GET_DETAILS_TRANSACTIONS_ERROR,
  payload: error,
});
