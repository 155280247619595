import {
    METRICS_GET_LIST,
    METRICS_GET_LIST_ERROR,
    METRICS_GET_LIST_SUCCESS,
  } from '../contants';
  
  export const getMetrics = (date) => ({
    type: METRICS_GET_LIST,
    payload: date,
  });
  
  export const getMetricsSuccess = (items) => ({
    type: METRICS_GET_LIST_SUCCESS,
    payload: items,
  });
  
  export const getMetricsError = (error) => ({
    type: METRICS_GET_LIST_ERROR,
    payload: error,
  });
  